import React, { useState } from "react"
import styled from "styled-components"

import Layout from "../theme/layout"
import SEO from "../components/header/seo"
import SearchBarPage from "../components/search/search-bar-page"
import CompactLoader from "../components/generic/loaders/compact-loader"
import { Link } from "gatsby"

const SearchPage = ({ location }) => {
  const maxSearchCount = 6
  const resultTypes = [
    { title: "Products", type: "products" },
    { title: "Categories", type: "categories" },
    { title: "Pages", type: "pages" },
    { title: "Blogs", type: "posts" },
  ]

  const defaultResults = {
    posts: [],
    categories: [],
    products: [],
    pages: [],
  }

  const [searchQuery, setSearchQuery] = useState(
    location.search.split("?query=")[1]
  )
  const [searchResponse, setSearchResponse] = useState(null)
  const [searchResults, setSearchResults] = useState(defaultResults)
  const [loading, setLoading] = useState(false)

  // convenience variable for number of results
  const searchResultCount =
    searchResults.products.length +
    searchResults.posts.length +
    searchResults.pages.length +
    searchResults.categories.length

  return (
    <Layout location={location}>
      <SEO title={"Home"} location={location} />
      <h1>Search results</h1>
      <SearchBarPage
        setSearchQuery={setSearchQuery}
        setSearchResponse={setSearchResponse}
        searchResults={searchResults}
        setSearchResults={setSearchResults}
        setLoading={setLoading}
        defaultResults={defaultResults}
        queryValue={location.search.split("?query=")[1]}
      ></SearchBarPage>
      {loading && (
        <LoaderStyling>
          <CompactLoader colour="black" />
        </LoaderStyling>
      )}
      {searchResultCount > 0
        ? resultTypes.map(
            (type) =>
              searchResults[type.type].length > 0 && (
                <>
                  <StyledH4>{type.title}</StyledH4>
                  {searchResults[type.type].map((result) => (
                    <h3>
                      <Link
                        to={`${result.type === "product" ? "/product" : ""}/${
                          result.slug
                        }`}
                      >
                        {result.title}
                      </Link>
                    </h3>
                  ))}
                </>
              )
          )
        : !loading && <h3>No results to display</h3>}
    </Layout>
  )
}

export default SearchPage

const StyledH4 = styled.h4`
  margin-top: 50px;
`

const LoaderStyling = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`
